import { ChangeDetectionStrategy, Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, ResolveFn, Router} from "@angular/router";

import {APIService} from "../../services/api.service";
import {IHashStartupData} from "../../services/api.types";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AuthService} from "../../services/auth.service";
import {DialogService} from "../../services/dialog.service";
import {ModalService} from "../../services/modal.service";
import {ProgressService} from "../../../oex-ui-kit/services/progress.service";
import {isPlatformBrowser} from "@angular/common";

export const resolveStartupHashData: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const hash = route.queryParamMap.get('hash');
  const router = inject(Router);
  const auth = inject(AuthService);
  const modal = inject(ModalService);
  // Already have a startup
  if (auth.user.startup?.id) {
    void inject(ModalService).show(`You already have a startup.`);
    return router.navigateByUrl('/portal/overview');
  }
  if (!hash) {
    return router.navigateByUrl('/');
  }
  return inject(APIService).getStartupByHash(hash)
    .catch(e => {
      modal.showError(e);
      return router.navigateByUrl('/');
    });
}

@Component({
  selector: 'st-link-startup-page',
  imports: [],
  templateUrl: './link-startup-page.component.html',
  styleUrl: './link-startup-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkStartupPageComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private api = inject(APIService);
  private modal = inject(ModalService);
  private ds = inject(DialogService);
  private ps = inject(ProgressService);
  private auth = inject(AuthService);
  private platformId = inject<Object>(PLATFORM_ID);

  model: IHashStartupData = {} as IHashStartupData;
  data$ = this.route.data.pipe(takeUntilDestroyed());

  ngOnInit() {
    this.data$.subscribe(d => {
      this.model = d['startup'];
    });
    // For auto accept - check query params, but only on browser
    if (isPlatformBrowser(this.platformId)) {
      if (this.route.snapshot.queryParamMap.get('accept') === '1') {
        void this.onConfirmClick();
      }
    }
  }

  async onConfirmClick() {
    if (!this.auth.isLogged) {
      this.ds.showUnAuthDialog(location.href + '&accept=1');
      return;
    }

    const hash = this.route.snapshot.queryParamMap.get('hash');
    if (!hash) {
      void this.router.navigateByUrl('/');
      return;
    }
    this.ps.show();
    try {
      const resp = await this.api.linkStartupToCurrentUser(hash);
      this.auth.user.startup = {
        id: resp.id,
        slug: resp.slug
      };
      void this.router.navigateByUrl('/portal/overview/' + this.auth.user.startup.slug);
    } catch (e) {
      this.modal.showError(e);
    }
    this.ps.hide();
  }
}
